import { ApiStore, Widget, rotationModes } from '@hart-estate/widget';
import '../assets/sass/index.sass';
import logo from '../assets/img/logo1.svg';

const WIDGET_API_URL_STAGE = 'https://backend-stage.estate.hart-digital.com';
const WIDGET_API_URL = 'https://backend.estate.hart-digital.com';

const createWidget = async (logoUrl) => {
  const searchParams = (new URL(document.location)).searchParams;

  const env = searchParams.get("env");
  const planId = searchParams.get("id");

  const widgetApiHandler = new ApiStore(env === 'stage' ? WIDGET_API_URL_STAGE : WIDGET_API_URL);

  const planData =  await widgetApiHandler.loadWidgetData(planId);
  const options = {
    ...planData.parsed,
    API_URL: WIDGET_API_URL,
    rotationMode: rotationModes.DEFAULT,
    logo: logo,
    tabs: ['panorama', 'area', 'rotation'],
    locale: 'en',
    logoUrl,
    colors: {
      main: '#5F449B',
      mainText: '#ffffff'
    },
    preloadPerPanoramaCount: 5
  }

  new Widget('#widget', options);
}

createWidget('https://www.squareyards.com/');

